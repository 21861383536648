module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"en-nz","omitPrismicScript":true,"pages":[{"type":"Ukulele","match":"/ukuleles/:uid","path":"/ukuleles","component":"/opt/build/repo/src/templates/ukulele.js"}],"path":"/","previews":false,"repositoryName":"captainukuleles"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
