const linkResolver = doc => {
  switch (doc.type) {
    case 'ukulele':
      return `/ukuleles/${doc.uid}`
    case 'home':
    case 'ukuleles':
    case 'process':
    case 'maker':
    case 'wood':
    case 'extras':
    case 'reviews':
    case 'contact':
      return `/${doc.uid}`
    default:
      return `/`
  }
}

export { linkResolver }
