// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-ukulele-js": () => import("./../src/templates/ukulele.js" /* webpackChunkName: "component---src-templates-ukulele-js" */),
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-extras-js": () => import("./../src/pages/extras.js" /* webpackChunkName: "component---src-pages-extras-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maker-js": () => import("./../src/pages/maker.js" /* webpackChunkName: "component---src-pages-maker-js" */),
  "component---src-pages-process-js": () => import("./../src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-reviews-js": () => import("./../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-ukuleles-js": () => import("./../src/pages/ukuleles.js" /* webpackChunkName: "component---src-pages-ukuleles-js" */),
  "component---src-pages-wood-js": () => import("./../src/pages/wood.js" /* webpackChunkName: "component---src-pages-wood-js" */)
}

